.details-table {
  box-sizing: border-box;
  font-size: 0.8rem;
  width: 100%;
  padding: 20px;
}

.details-table .title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 2rem;
}

.details-table table {
  box-sizing: border-box;
  width: 100%;
}

.details-table tr {
  line-height: 3rem;
  width: 100%;
  border-bottom: 1px solid #c1baba;
}

.details-table td {
  width: 25%;
  line-height: 1.5rem;
  white-space: nowrap;
}

.details-table table thead th {
  font-weight: bold;
  text-align: left;
}
