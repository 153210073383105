.root {
  display: flex;
  flex-direction: column;
  background-image: url('./login-bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  padding: 16px;
  margin: auto;
  max-width: 414px;
  max-height: 823px;
}

.logo {
  width: 65%;
  margin: 0 auto;
  display: block;
  flex-grow: 1;
}

.wrapper {
  flex-grow: 1;
}
